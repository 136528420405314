import './ArtPage.scss'
import * as data from './artData.js'

const ArtPage = () => {
  return (
    <section className="container art-page">
      <section className="gallery">
      {
        data.artData.map(image => {
          return (
              <div key={image.id + "img"} style={{backgroundImage: `url(${image.path})`}} className={`gallery-img gallery__img--${image.id}`} alt={`image ${image.name}`}></div>
          )
        })
      }
      </section>
    </section>
  )
}

export default ArtPage;
