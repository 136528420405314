import island from '../../Assets/art/island.png'
import ocean from '../../Assets/art/ocean.png'
import field from '../../Assets/art/field.png'
import choize from '../../Assets/art/choize.png'
import liveArtSceleton from '../../Assets/art/traditional_1.png'
import liveArtShell from '../../Assets/art/traditional_2.png'
import liveArtHand from '../../Assets/art/traditional_3.png'

export const artData = [
  {
    id: 1,
    path: island,
    name: "Sakhalin Island"
  },
  {
    id: 2,
    path: liveArtHand,
    name: "Ocean"
  },
  {
    id: 3,
    path: field,
    name: "Flowers"
  },
  {
    id: 4,
    path: liveArtShell,
    name: "Traditional Art 1"
  },
  {
    id: 5,
    path: choize,
    name: "Choize"
  },
  {
    id: 6,
    path: ocean,
    name: "Traditional Art 3"
  }
]

