
import projectsData from '../Projects/projectsInfo';
import './Home.scss';
import { useSelector } from 'react-redux';
import colors from '../common/styleVariables';

const ProjectCards = () => {
  const userTheme = useSelector((state) => state.theme);

  const projectCard = (description, image, name, repoLink, deployLink, idx) => 
    <div className="project-card">
      <div className="title">{name}</div>
      <div className="content">
        <div className="description">{description}</div>
        <div className="icon-holder">
          {repoLink ? 
            <a style={{color: `${colors[userTheme].textColor}`}} href={repoLink} className="title"><span className="git-repo-link">Check Git Repo</span>
            <img className="project-icon" style={{width:"20px", height: "20"}} src={`${colors[userTheme].icon}`}/>
            </a>
            : null
          }
          {deployLink ?
            <a style={{color: `${colors[userTheme].textColor}`}} href={deployLink} className="title"><span className="git-repo-link">Visit {name}</span><img className="project-icon" style={{width:"20px", height: "20"}} src={`${colors[userTheme].icon}`}/></a>
            : null
          }
        </div>
      </div>
    </div>

  return (
    <section className="wrap projects-inner">
          <ul className="projects-list">
            {
              projectsData.map((card, idx ) =>
                <li key={`card-${idx}`} className="card">
                  {projectCard(card.description, card.image, card.name, card.repoLink, card.deployLink, idx)} 
                </li>
              )
            }
          </ul>
        </section>
  )
}

export default ProjectCards;