import './Home.scss'
import ProjectCards from './ProjectCards'
import colors from '../common/styleVariables'
import ResumePDF from '../../Assets/OlgaKalimbetResume.pdf'

const Home = ({ theme }) => {

  const text = "Hello! My name is Olia. I'm a passionate engineer who thrives in the world of frontend development but is equally adept at full-stack solutions. With a love for creating beautiful, user-friendly interfaces, I bring both technical expertise and a creative touch to every project. Beyond coding, I enjoy immersing myself in music, exploring the vibrant world of art, and savoring a good cup of coffee."
  return (
    <section className="container container-body">
      <section className="wrap title-page">
        <h1 className="header portfolio-title" style={{backgroundColor: `${colors[theme].titleColor}`}}>
          <span className="highlight" style={{backgroundColor: `${colors[theme].titleColor}`}}>
            <span className="role">Front End Engineer</span>
          </span>
          <span className="place">from Sakhalin</span>
        </h1>
        <section className="container-p-nav">
        <div className="header-paragraph-wrap">
          <p className="header-paragraph">
            {
              text.split(' ').map((letter, index) => {
                return <span key={`letter-${index}`} 
                    style={{
                      animationDelay: `${0.9 + index / 10}s`,
                      color: `${colors[theme].textColor}`
                    }} 
                    className="header__letter">{letter.length ? letter + " " : " " }
                  </span>
              })
            }
          </p>
        </div>
        <div className="title-page__nav-box">
          <a href="/myart" className="title-page__nav-item" style={{color: `${colors[theme].textColor}`}}>
            <span className="nav-box-text">Peek at my personal art projects</span>
            <span className="nav-box-img" role="img" aria-label="paint">🎨	</span>
          </a>
          <a href={ResumePDF} style={{color: `${colors[theme].textColor}`}} className="title-page__nav-item">
            <span className="nav-box-text">View my resume</span>
            <span className="nav-box-img" role="img" aria-label="document">📄</span>
          </a>
          <a href="#projects-page" className="title-page__nav-item" style={{color: `${colors[theme].textColor}`}}>
            <span className="nav-box-text">Explore my projects</span>
            <span className="nav-box-img" role="img" aria-label="pointer">👇</span>
          </a>
        </div>
        </section>
      </section>
      <section className="container projects-wrap" id="projects-page">
        <ProjectCards/>
      </section>
    </section>
  )
}

export default Home;