import ArrowBlack from '../../Assets/arrow-bl.png'
import ArrowWhite from '../../Assets/arrow-wh.png'
import SunBL from '../../Assets/slidebar/sun_bl.png'
import SunWh from '../../Assets/slidebar/sun2_wh.png'
import EveBl from '../../Assets/slidebar/evening_bl.png'
import EveWh from '../../Assets/slidebar/evening_wh.png'
import MoonBl from '../../Assets/slidebar/moon_bl.png'
import MoonWh from '../../Assets/slidebar/moon_wh.png'

const colors = {
  morning: {
    background: `rgb(255, 206, 206), rgb(141, 214, 236)`,
    titleColor: `rgb(180, 113, 113)`,
    primaryColor: `rgba(212, 174, 174, 0.8)`,
    secondaryColor: 'rgb(129, 69, 69)',
    textColor: `rgb(0, 0, 0)`,
    underLine: `rgb(0, 0, 0)`,
    icon: ArrowBlack,
    bar: `rgba(255, 255, 255, 0.3)`,
    iconSun: SunBL,
    iconEve: EveBl,
    iconMoon: MoonBl,
  },
  evening:{
    background: `rgb(199, 139, 124), rgb(117, 41, 80)`,
    titleColor: `rgb(80, 17, 58)`,
    primaryColor: `rgb(187, 77, 118)`,
    secondaryColor: 'rgb(45, 1, 45)',
    textColor: `rgb(255, 255, 255)`,
    underLine: `rgb(255, 255, 255)`,
    icon: ArrowWhite,
    bar: `rgba(200, 200, 200, 0.3)`,
    iconSun: SunWh,
    iconEve: EveWh,
    iconMoon: MoonWh,
  },
  night: {
    background: `rgb(46, 66, 97), rgb(2, 20, 47)`,
    titleColor: `rgb(51, 94, 128)`,
    primaryColor: `rgb(124, 149, 185)`,
    secondaryColor: `rgb(2, 20, 47)`,
    textColor: `rgb(255, 255, 255)`,
    underLine: `rgb(255, 255, 255)`,
    icon: ArrowWhite,
    bar: `rgba(90, 103, 119, 0.36)`,
    iconSun: SunWh,
    iconEve: EveWh,
    iconMoon: MoonWh,
  }
}

export default colors;