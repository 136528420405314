import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import './MediaBar.scss'
import '../NavBar/NavBar.scss'
import GitLogo from '../../Assets/github-logo.png'
import LinkLogo from '../../Assets/linkedin.png'
import EmailLogo from '../../Assets/email.png'
import Turing from '../../Assets/turing.png'
import styled from 'styled-components'
import colors from '../common/styleVariables'

const MediaBar = () => {
  const  [opacity, setOpacity] = useState('1')
  const userTheme = useSelector((state) => state.theme);
  const myLinkedIn = `https://www.linkedin.com/in/olgamorgan/`;
  const myTuringPortfolio = `https://terminal.turing.edu/profiles/534`;
  const myEmail = `mailto:olga.morgan17@gmail.com?`;
  const myGitHub = `https://github.com/scripka`;

  const MediaLink = styled.a`
    background: linear-gradient(145deg, ${colors[userTheme].primaryColor}, ${colors[userTheme].titleColor});
    &:hover {
      box-shadow:inset 2px 2px 20px ${colors[userTheme].secondaryColor},
      inset -2px -2px 20px ${colors[userTheme].primaryColor};
    }
    &:active, :focus {
      box-shadow:inset 2px 2px 20px ${colors[userTheme].secondaryColor},
      inset -2px -2px 20px ${colors[userTheme].primaryColor};
    }          
  `;

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.onscroll = () => {
        let currentScrollPos = window.pageYOffset;
        let maxScroll = document.body.scrollHeight - window.innerHeight;
        if (currentScrollPos > 0 && currentScrollPos < maxScroll) {
          setOpacity("0")
        } else {
          setOpacity("1")
        }
      }
    }
  })

  return (
      <div className="media-container" style={{ 
        opacity: `${opacity}`,
        background:`${colors[userTheme].bar}`
        }} >
        <div className="navbar-header">
          <ul className="nav navbar-media">
            <li className="media">
              <MediaLink href={myGitHub} className="media-btn">
                <img className="media-img med1-img" src={GitLogo}/>
              </MediaLink>
            </li>
            <li className="media">
              <MediaLink href={myLinkedIn} className="media-btn">
                <img className="media-img med2-img" src={LinkLogo}/>
              </MediaLink>
            </li>
            <li className="media">
              <MediaLink href={myEmail} className="media-btn">
                <img className="media-img med3-img" src={EmailLogo}/>
              </MediaLink>
            </li>
            <li className="media">
              <MediaLink href={myTuringPortfolio} className="media-btn">
                <img className="media-img med4-img"src={Turing}/>
              </MediaLink>
            </li>
          </ul>
        </div>
      </div>
  )
}

export default MediaBar;