import { useDispatch, useSelector } from 'react-redux'
import { setTheme } from '../../redux'
import { useState, useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import colors from '../common/styleVariables'
import Tooltip from '@material-ui/core/Tooltip';

const useStylesOne = makeStyles(theme => ({
  arrow: {
    color: `white!important`,
  },
  tooltip: {
    color: `black!important`,
    backgroundColor:  `white!important`,
  }
}));

const WhiteTooltip = (props) => {
  const classes = useStylesOne();
  return <Tooltip classes={classes} {...props} />;
}

const ModeBar = (props) => {
  const userTheme = useSelector((state) => state.theme);
  const [mode, setMode] = useState('')
  const [state, setState] = useState(false);
  
  const marks = [
    {
      value: 1,
      label:  colors['evening'].iconSun,
      time: "morning"
    },
    {
      value: 2,
      label:  colors['evening'].iconEve,
      time: "evening"
    },
    {
      value: 3,
      label:  colors['evening'].iconMoon,
      time: "night"
    },
  ];

  const dispatch = useDispatch()
  const container = useRef();
  
  const changeTheme = (e, theme) => {
    e.preventDefault()
    let chosenTheme = marks.find(mode => mode.value === theme)
    dispatch(setTheme(chosenTheme.time))
  }

  const handleButtonClick = () => {
    setState((state) =>  !state);
    console.log(state)
    getImage(userTheme)
  };

  const getImage = (mode) => {
    let chosenTheme = marks.find(userMode => userMode.time === mode)
    setMode(chosenTheme.label)
  };

  const handleClickOutside = (event) => {
    if (
      container.current &&
      !container.current.contains(event.target)
    ) {
      setState(
        false
      );
    }
  };
 
  useEffect(() => {
    window.addEventListener('mousemove', handleClickOutside);
    getImage(userTheme)
    return () => {
      window.removeEventListener('mousemove', handleClickOutside)
    }
  }, [state])
 
  return (
    <section className="wrap-dropdown">
      <div className="container-dropdown" ref={container}>
        <button type="button" className="button" onClick={handleButtonClick}>
          <WhiteTooltip placement="right" disableFocusListener disableTouchListener title='theme'><img style={{width: `28px`}} src={mode}/></WhiteTooltip> 
        </button>
        {state &&
          <div className="dropdown">
            <ul className="dropdown__drop-list">
              {
                marks.map(el => {
                  return(
                    <WhiteTooltip key={`more-${el.value}`} placement="right" disableFocusListener disableTouchListener title={el.time}>
                      <li className="dropdown__drop-item" onClick={(e) => changeTheme(e, el.value)} key={`theme-${el.value}`} value={el.time}><img className="theme-img"style={{width: `28px`}} src={`${el.label}`} alt={`theme-${el.time}`}/></li>
                    </WhiteTooltip> 
                    
                    )
                })
              }
            </ul>
          </div>
        }
      </div>
    </section>
  )
}

export default ModeBar;