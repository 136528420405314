import './App.scss';
import { Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Home from '../Home'
import Projects from '../Projects'
import NavBar from '../NavBar'
import MyIndex from '../MediaBar'
import ArtPage from '../ArtPage'
import colors from '../common/styleVariables'

const App = (props) => {
  const userTheme = useSelector((state) => state.theme);
  
  return (
    <main 
      style={{
        background: `linear-gradient(15deg, ${colors[userTheme].background})`,
        color: `${colors[userTheme].textColor}`
      }}
      className="App"
    >
      <NavBar theme={userTheme}/>
      <Switch>
        <Route exact path ='/'
          render={() => <Home theme={userTheme} />}/>
          <Route exact path ='/projects'
          render={() => <Projects theme={userTheme}/>}/>
          <Route exact path ='/myart'
          render={() => <ArtPage theme={userTheme}/>}/>
        </Switch>
        <MyIndex/>
    </main>
  );
}

export default App;
