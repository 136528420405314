import { createStore } from 'redux'

export const ACTIONS = {
  TOGGLE_THEME: 'TOGGLE_THEME',
};

const initialState = {
  theme: 'morning',
}

export const setTheme = theme => ({
  type: 'TOGGLE_THEME',
  payload: theme,
});

function feedReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.TOGGLE_THEME: {
      return {
        ...state,
        theme: action.payload
      };
    }
    // other cases
    default: 
      return state
  }
}

export function createReduxStore() {
  const store = createStore(feedReducer, enableReduxDevTools);
  return store;
}

const enableReduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__?.();