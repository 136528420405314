import GitHubCards from './GitHubCard.js'
import projectsData from './projectsInfo';
import './Projects.scss'

const Projects = () => {
  return (
    <section className="container projects-page">
      <section className="gallery-projects">
      {projectsData &&
        <GitHubCards projects={projectsData} />
      }
      </section>
    </section>
  )
}

export default Projects;