
import './Projects.scss'
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import CodeIcon from '@material-ui/icons/Code';
import WebIcon from '@material-ui/icons/Web';
import Tooltip from '@material-ui/core/Tooltip';
import colors from '../common/styleVariables';
import { useSelector } from 'react-redux';

const GitHubCard = (projects) => {
  const userTheme = useSelector((state) => state.theme);

  if(projects) {
    return (
      <section className="projects-wrap">
        { projects.projects.map((item) => (
          <ImageListItem key={item.id+"project"} sx={{ transition: '2s', maxWidth: 450, margin:"1.2rem" }}>
            <img
              srcSet={`${item.image}?w=248&fit=crop&auto=format 1x,
                  ${item.image}?w=248&fit=crop&auto=format 2x`}
              alt={item.name}
              loading="lazy"
            />
            <ImageListItemBar
              sx={{
                textAlign: `left`,
              }}
              title={item.name}
              actionIcon={
                <div>
                  {item.repoLink ?
                    <Tooltip disableFocusListener disableTouchListener title="GitHub">
                      <a style={{color: `${colors[userTheme].textColor}`}}  className="project-links" href={item.repoLink}><CodeIcon sx={{ color: 'white'}}/></a>
                    </Tooltip> : <Tooltip disableFocusListener disableTouchListener title="">
                      <a className="project-links"><CodeIcon sx={{ fill: 'grey'}}/></a>
                    </Tooltip> 

                  }
                  {
                  item.deployLink ?
                  <Tooltip disableFocusListener disableTouchListener title="Website">
                    <a style={{color: `${colors[userTheme].textColor}`}}  className="project-links" href={item.deployLink}><WebIcon sx={{ color: 'white'}}/></a>
                  </Tooltip> : <Tooltip disableFocusListener disableTouchListener title="">
                    <a  className="project-links"><WebIcon sx={{ fill: 'grey'}}/></a>
                  </Tooltip> 
                  }
                </div>
              }
            />
          </ImageListItem>
        ))}
      </section>
      )
    }
}
export default GitHubCard;
