import favo from '../../Assets/favo.png'
import jobquest from '../../Assets/jobquest.png'
import overlook from '../../Assets/overlook.png'
import rotten from '../../Assets/rotten.png'
import slapjack from '../../Assets/slapjack.png'

const projectsData = [
  {
    id: 1,
    image: jobquest,
    name: 'JobQuest',
    tech: ['TypeScript', "SCSS", "React Router", "Cypress", "Auth0"],
    repoLink: 'https://github.com/JobQuest/jobquest-fe',
    description: "JobQuest is a text-based RPG-style campaign that aids in the job search process, while making it fun! A user can login or create an account to track their progress through different quests to defeat fantastical monsters."
  },

  {
    id: 2,
    image: favo,
    name: 'Favo',
    tech: ['ReactJS', "ExpressJS", "JavaScript", "SCSS", "Cypress", "Jest"],
    deployLink: 'https://favo.vercel.app/',
    repoLink: 'https://github.com/scripka/favo',
    description: "Favo is aimed to build community making volunteering a fun experience, where people can contribute time, resources, or skills to help each other."
  },
  {
    id: 3,
    image: rotten,
    name: 'Rotten Tomatillos',
    tech: ["ReactJS", "SCSS", "Jest", "React Router"],
    deployLink: 'https://shawntru.github.io/Rotten-Tomatillos/',
    repoLink: 'https://github.com/scripka/Rotten-Tomatillos',
    description:"Rancid Tomatillos is a movie rating and information site where a user can get trailers and info for new and upcoming films, which are pulled from a heroku API."
  },
  {
    id: 4,
    image: overlook,
    name: 'Overlook Hotel',
    tech: ["JavaScript", "HTML", "SCSS", "Chai Spies"],
    repoLink: 'https://github.com/scripka/Overlook-project',
    description: "The Overlook is a website which represents a hotel management tool for hotel customers and staff to manage room bookings and calculate customer bills."
  },
  {
    id: 5,
    image: slapjack,
    name: 'SlapJack',
    tech: ["JavaScript", "HTML", "CSS"],
    deployLink: null,
    repoLink: 'https://github.com/scripka/slapjack',
    description: "Slapjack is a simple game with with the 52-cards deck and two players instances. A player wins when their opponent loses all of their cards."
  }
]

export default projectsData;