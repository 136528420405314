import './NavBar.scss';
import {Link, useLocation} from 'react-router-dom';
import { useEffect, useState } from 'react';
import ModeBar from './ModeBar';
import colors from '../common/styleVariables';
import ResumePDF from '../../Assets/OlgaKalimbetResume.pdf';


const NavBar = ({ theme }) => {
  const [currentPath, setCurrentPath] = useState(null)
  let location = useLocation();

  useEffect(()=> {
    setCurrentPath(location.pathname)
  }, [location])

  return(
    <nav className="navbar navbar-fixed-top container-fluid" id="navbar">
      <ModeBar/>
      <div className="collapse navbar-right navbar-collapse" id="myNavbar">
        <ul className="nav navbar-nav">
          <li>
            <Link 
              style={{color: `${colors[theme].textColor}`}} 
              className={`nav-link ${currentPath === '/' ? 'is-active' : ''}`} 
              to="/">Home
            </Link>
          </li>
          <li>
            <Link 
              style={{color: `${colors[theme].textColor}`}} 
              className={`nav-link ${currentPath === '/projects' ? 'is-active' : ''}`} 
              to="/projects">Projects
            </Link>
          </li>
          <li>
            <Link 
              style={{color: `${colors[theme].textColor}`}} 
              className={`nav-link ${currentPath === '/myart' ? 'is-active' : ''}`} 
              to="/myart">Art
            </Link>
          </li>
          <li>
            <a 
              style={{color: `${colors[theme].textColor}`}} 
              href={ResumePDF} 
              className="nav-link">Resume
            </a>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default NavBar;